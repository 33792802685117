/* App.css */

/* Enable smooth scrolling for the entire page */
/* html {
  scroll-behavior: smooth;
} */

/* Navbar Container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #333;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo */
.navbar-logo a {
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

/* Links */
.navbar-links {
  display: flex;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-links a {
  position: relative;
  text-decoration: none;
  font-size: 18px;
  color: white; /* Base color */
  padding: 5px 10px;
  transition: color 0.3s ease;
}

/* Adding the animated underline */
.navbar-links a::after {
  content: ""; /* Required for pseudo-element */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px; /* Thickness of the underline */
  transition: width 0.3s ease; /* Smooth animation */
}




/* Icons */
.navbar-icons a {
  margin-left: 15px;
  color: #333;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-icons a:hover {
  color: #1a73e8;
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-icons {
    font-size: 20px;
  }
}

@media (min-width: 769px) {
  .navbar-links {
    display: flex;
  }
}


/* Section Styling */
section {
  margin-top: 50px;
  padding: 80px 20px;
  text-align: center;
}


/* .hero { */
  /* background-color: .welcome { */
  /* background: #0670b8; */
  /* color: white; Light text color for contrast */
  /* padding: 80px 20px; Adjust padding as needed */
  /* text-align: center; */
/* };  Lighter blue */
/* } */

.benefits {
  background-color: #f9f9f9;
}

.backstory {
  background: linear-gradient(135deg, #1a73e8, #3498db); /* Modern blue gradient */
}

.product-showcase {
  background-color: #fafafa;
}

.cta {
  padding: 20px;
}

.cta a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #007bff;
}

.cta a:hover {
  background-color: #0056b3;
}

/* Benefit Item Hover Effect */
.benefit-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 2 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 40px 20px; /* Increase vertical gap to 40px and decrease horizontal gap to 20px */
  justify-items: center; /* Center the cards horizontally */
}

.benefit-item {
  width: 100%;
  max-width: 350px; /* Adjust width to make cards closer together but larger */
  height: 350px; /* Fixed height */
  perspective: 1000px; /* 3D effect for flip */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}



.card {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out; /* Smooth flipping effect */
  border-radius: 15px; /* Rounded corners for the card */
}

.card.flipped {
  transform: rotateY(180deg); /* Flip the card */
}

/* Front of the card */
/* App.css */

/* Front of the card */
.front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back when flipped */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #0982d3, #000000bb); /* Gradient from blue to white */
  color: #ecf0f1; /* Light gray text for better contrast */
  font-size: 18px;
  text-align: center;
  border-radius: 15px; /* Rounded corners */
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.2); /* Soft inner shadow for depth */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Transition for smooth effect */
}


/* Hover effect on cards */
.benefit-item:hover {
  transform: translateY(-10px); /* Slightly lift the card */
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.2); /* Larger shadow on hover for depth */
}

/* Text styling for the cards */
h3 {
  font-size: 1.4rem;
  font-weight: bold;
}

p {
  font-size: 1rem;
  /* hero and card small characters*/
  color: rgb(247, 247, 247);
  color: black;
  line-height: 1.4;
  padding: 10px;
  text-align: center;
}

invention {
  background: linear-gradient(135deg, #1a73e8, #3498db);
  color: white;
  padding: 20px;
  width: 100%;
  /* borderRadius: 10px; */
}

/* Footer Styling */
.footer {
  background-color: #333; /* Modern blue */
  color: white;
  text-align: center;
  /* padding: 0px; */
  position: relative; /* Ensures it stays below content */
  bottom: 0;
  width: 100%;
  font-size: 14px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Adds a slight shadow at the top of the footer */
}

.footer p {
  margin: 5px 0;
}

.footer a {
  color: #ffd700; /* Gold color for links */
  text-decoration: none;
  font-weight: bold;
}

.footer a:hover {
  text-decoration: underline;
}

/* Ensure content doesn't overlap with the navbar */
body {
  margin-top: 0;
  padding-top: 0;
}

/* Ultimate Dental Guard Section */
.ultimate-dental-guard {
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */
  text-align: center; /* Center align text within the section */
  padding: 60px 20px;
  background: linear-gradient(135deg, #1a73e8, #3498db); /* Modern blue gradient */
  color: white;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  gap: 40px; /* Space between image and description */
  text-align: left; /* Keep the text aligned left inside the description container */
}

/* Ensure the image and text container remain equal */
.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded corners for the image */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.description-container {
  max-width: 600px;
  position: relative;
}

.description-container::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1); /* Underlay effect */
  border-radius: 10px;
  z-index: -1; /* Place the underlay behind the text */
}

.description-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.description-container p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Ensure responsive adjustments for mobile */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    gap: 20px;
    text-align: center; /* Center-align text for smaller screens */
  }

  .description-container {
    max-width: 100%; /* Allow full width for text on smaller screens */
  }
}
